@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?z9lk0m');
  src:  url('fonts/icomoon.eot?z9lk0m#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?z9lk0m') format('truetype'),
    url('fonts/icomoon.woff?z9lk0m') format('woff'),
    url('fonts/icomoon.svg?z9lk0m#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.box {
  display: inline-block;
  padding: 10px;
  box-sizing: border-box;
}
.img-preview {
  overflow: hidden;
  border-radius: 50%;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  /* speak: none; */
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-parfums:before {
  content: "\e900";
}
.icon-jewerly:before {
  content: "\e901";
}
.icon-man:before {
  content: "\e902";
}
.icon-face:before {
  content: "\e903";
}
.icon-catalog:before {
  content: "\e904";
}
.icon-for-body:before {
  content: "\e905";
}
.icon-for-home:before {
  content: "\e906";
}
.icon-child:before {
  content: "\e907";
}
.icon-makeup:before {
  content: "\e908";
}
.icon-hair:before {
  content: "\e909";
}
.icon-health:before {
  content: "\e90a";
}
