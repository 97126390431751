@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
/* Load in your font */

.tree-wrapper {   
  height: 500px;
} 

/* .tree-wrapper {
  height: calc(100% - 25px);
} */


.tab{
  font-weight: 400;
}
.tab::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0;
    height: 0;
}
.menu-price{
  text-align: right;
  font-size: 1rem;
  display: inline-block;
  width: 66px !important;
  margin-right: 8px;
}

.rstcustom__collapseButton::after, 
.rstcustom__expandButton::after {
  border-top-color:#1876d2;   
}

.price--line-through{
  background-color: transparent;
  background-image: gradient(linear, 19.1% -7.9%, 81% 107.9%, color-stop(0, transparent), color-stop(.48, transparent), color-stop(.5, #f50606), color-stop(.52, transparent), color-stop(1, transparent));
  background-image: repeating-linear-gradient(170deg, transparent 0%, transparent 48%, #f50606 50%, transparent 52%, transparent 100%);
}
.text-gray{
  color: #616161;
}
.ReactVirtualized__Grid__innerScrollContainer { overflow: visible !important; overflow: initial !important; }

.burst-8 {
  top: 8px;
  left: 10px;
  background: red;
  width: 50px;
  height: 50px;
  position: absolute;
  text-align: center;
  -webkit-transform: rotate(20deg);
          transform: rotate(20deg);
}
.burst-8:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 50px;
  width: 50px;
  background: red;
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
}

@font-face {
  font-family: 'icomoon';
  src:  url(/static/media/icomoon.d4fa3f91.eot);
  src:  url(/static/media/icomoon.d4fa3f91.eot#iefix) format('embedded-opentype'),
    url(/static/media/icomoon.03b1a2d9.ttf) format('truetype'),
    url(/static/media/icomoon.ef0b7bf1.woff) format('woff'),
    url(/static/media/icomoon.4ed31ba9.svg#icomoon) format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.box {
  display: inline-block;
  padding: 10px;
  box-sizing: border-box;
}
.img-preview {
  overflow: hidden;
  border-radius: 50%;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  /* speak: none; */
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-parfums:before {
  content: "\e900";
}
.icon-jewerly:before {
  content: "\e901";
}
.icon-man:before {
  content: "\e902";
}
.icon-face:before {
  content: "\e903";
}
.icon-catalog:before {
  content: "\e904";
}
.icon-for-body:before {
  content: "\e905";
}
.icon-for-home:before {
  content: "\e906";
}
.icon-child:before {
  content: "\e907";
}
.icon-makeup:before {
  content: "\e908";
}
.icon-hair:before {
  content: "\e909";
}
.icon-health:before {
  content: "\e90a";
}

